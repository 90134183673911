import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown/with-html";
import { LinkRenderer } from "../../utils/utilities";

//components
import useSetPageData from "../../utils/useSetPageData";
import SEO from "../../components/seo";
import Hero from "../../components/panels/Hero";
import SwitchComponent from "../../components/panels/Switch";
import Partners from "../../components/panels/Partners";

//styles
import s from "./about.module.scss";

const CompanyPage = ({ location, data }) => {
  const {
    SEO: seo,
    hero,
    our_story,
    our_story_heading,
    our_story_image
  } = data.strapiPaCompany;

  useSetPageData(location);

  return (
    <>
      <SEO
        {...{
          title: `${hero[0].main_heading} | About`,
          description: seo.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        icon={hero[0].about_icon.childImageSharp.fixed}
        videoImage={hero[0].about_hero_image.childImageSharp.fixed.src}
      />
      <div style={{ marginTop: "4rem" }}></div>
      <div className={`container-fluid`}>
        <div className={`container`}>
          <h2 className="text-primary">Our Story</h2>
        </div>
      </div>
      <SwitchComponent
        contents={[
          {
            image: <Img fluid={our_story_image.childImageSharp.fluid} />,
            heading: our_story_heading,
            content: (
              <ReactMarkdown
                className={s.switch}
                source={our_story}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            )
          }
        ]}
      />
      <Partners />
    </>
  );
};

export default CompanyPage;

export const pageQuery = graphql`
  query {
    strapiPaCompany {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        about_hero_image {
          childImageSharp {
            fixed(width: 368, height: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        about_icon {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      our_story
      our_story_heading
      our_story_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
